export default function AboutMe() {
    return (
        <div className="main-content">
        <div className="post">
            <div className="post-content">
                Hi, I'm Shack de Shnaek. I live in Macedonia. I like programming shitty websites like this, and shitty C++ console apps.<br />
                I like games, anime, manga, manhwa, aircraft, and other shiz.<br />
                I know some HTML, CSS, Javascript, Python, and some C++. I'm currently learning Svelte, because Rich Harris is one sexy motherfucker.<br />
                My diet consists entirely of ice coffee and sandwiches.<br />
            </div>
        </div>
    </div>
    )
}
