export default function AboutSite() {
    return (
        <div className="main-content">
        <div className="post">
            <div className="post-content">
                This is mostly just a test site for me to practice my HTML CSS and JS. I don't have any particular plans for it. <br />
                I'll probably just add some random shit to it when/if I feel like it.
            </div>
        </div>
    </div>
    )
}
