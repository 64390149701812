export default function Contact() {
    return (
        <div className="main-content">
        <div className="post">
            <div className="post-content" id="penguins-post-content">
                <a href="https://steamcommunity.com/id/themechanicio/">My steam</a><br />
                My Discord: ShackdeShnaek#1040<br />
            </div>
        </div>
        </div>
    )
}
